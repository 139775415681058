import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
export default {
  name: 'AssetsIndex',
  mixins: [common],
  data() {
    return {
      menuListData: [{
        id: '1',
        name: '入库',
        svgName: 'assets_in',
        backgroundColor: 'transparent',
        routeName: 'AssetsIn',
        isShow: true
      }, {
        id: '2',
        name: '入库记录',
        svgName: 'assets_in_log',
        backgroundColor: 'transparent',
        routeName: 'AssetsInLog',
        isShow: true
      }, {
        id: '3',
        name: '盘点',
        svgName: 'assets_check_select',
        backgroundColor: 'transparent',
        routeName: 'AssetsCheckSelect',
        isShow: true
      }, {
        id: '4',
        name: '盘点记录',
        svgName: 'assets_check_log',
        backgroundColor: 'transparent',
        routeName: 'AssetsCheckLog',
        isShow: true
      }, {
        id: '5',
        name: '申请领用',
        svgName: 'assets_take',
        backgroundColor: 'transparent',
        routeName: 'AssetsTake',
        isShow: true
      }, {
        id: '6',
        name: '领用记录',
        svgName: 'assets_take_log',
        backgroundColor: 'transparent',
        routeName: 'AssetsTakeLog',
        isShow: true
      }, {
        id: '7',
        name: '处置',
        svgName: 'assets_dispose',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispose',
        isShow: true
      }, {
        id: '8',
        name: '处置记录',
        svgName: 'assets_dispose_log',
        backgroundColor: 'transparent',
        routeName: 'AssetsDisposeLog',
        isShow: true
      }, {
        id: '9',
        name: '调拨',
        svgName: 'assets_dispatch',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatch',
        isShow: true
      }, {
        id: '10',
        name: '添加物资',
        svgName: 'assets_in',
        backgroundColor: 'transparent',
        routeName: 'AssetsAdd',
        isShow: true
      }, {
        id: '11',
        name: '添加房内资产',
        svgName: 'assets_in',
        backgroundColor: 'transparent',
        routeName: 'AssetsAdd2',
        isShow: true
      }]
    };
  },
  methods: {
    goModule(data = {}) {
      let {
        routeName
      } = data;
      this.$router.push({
        name: routeName
      });
    }
  },
  created() {
    const {
      token,
      projectId,
      projectName,
      userId,
      userName
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName,
        projectName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId,
        projectName
      });
    }
  }
};